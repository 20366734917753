import { styled } from '@/styled'

export const HeaderWrapper = styled('div', {
  padding: '16px 24px',
  borderBottom: '1px solid $smokeLine',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '60px',
  zIndex: 9,
  backgroundColor: '$white',
})
