interface Props {
  size?: number
  color?: string
}

const IconWarning = ({ size = 24, color = '#202020' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40005 38.9769C5.20005 40.1908 6.60005 41 8.00005 41H40C41.4001 41 42.8001 40.1908 43.4001 38.9769C44.2001 37.763 44.2001 36.1445 43.4001 34.9306L27.4 8.02312C26.8 6.80925 25.4 6 24 6C22.6 6 21.4 6.80925 20.6 8.02312L4.40005 34.9306C3.60005 36.1445 3.60005 37.763 4.40005 38.9769ZM7.20005 36.3468L23 9.64162C23.4 9.03468 23.8 8.83237 24 8.83237C24.2001 8.83237 24.8 9.03468 25 9.43931L41 36.3468C41.1439 36.6378 41.0808 36.9289 41.0341 37.1446C41.0158 37.2288 41 37.3016 41 37.3584C40.9628 37.396 40.9256 37.4407 40.8858 37.4885C40.7117 37.6973 40.4884 37.9653 40 37.9653H8.20005C7.60005 37.9653 7.20005 37.5607 7.20005 37.3584C7.00005 37.3584 7.00005 36.7514 7.20005 36.3468ZM24 27.8497C23.2 27.8497 22.4 27.2428 22.4 26.2312V20.1618C22.4 19.3526 23 18.5433 24 18.5433C25 18.5433 25.6 19.1503 25.6 20.1618V26.4335C25.6 27.2428 24.8 27.8497 24 27.8497ZM24.0001 33.5144C24.7733 33.5144 25.4001 32.8803 25.4001 32.0982C25.4001 31.3161 24.7733 30.682 24.0001 30.682C23.2269 30.682 22.6001 31.3161 22.6001 32.0982C22.6001 32.8803 23.2269 33.5144 24.0001 33.5144Z"
        fill={color}
      />
    </svg>
  )
}

export default IconWarning
