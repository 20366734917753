interface IconProps {
  size?: number
  color?: string
}

const IconTime = ({ size = 20, color = '#5E2EBA' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7077 10.0003C17.7077 14.2578 14.2568 17.7087 9.99935 17.7087C5.74185 17.7087 2.29102 14.2578 2.29102 10.0003C2.29102 5.74283 5.74185 2.29199 9.99935 2.29199C14.2568 2.29199 17.7077 5.74283 17.7077 10.0003Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8594 12.4524L9.71777 10.5782V6.53906"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconTime
