interface Props {
  size?: number
  color?: string
}

const IconRoundedClose = ({ size = 24, color = '#F04A41' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10ZM1.5 10C1.5 14.7 5.3 18.5 10 18.5C14.7 18.5 18.5 14.7 18.5 10C18.5 5.3 14.7 1.5 10 1.5C5.3 1.5 1.5 5.3 1.5 10ZM11.7 7.2001C12 6.9001 12.5 6.9001 12.8 7.2001C13.1 7.5001 13.1 8.0001 12.8 8.3001L11.1 10.0001L12.7 11.7001C13 12.0001 13 12.5001 12.7 12.8001C12.6 12.9001 12.4 13.0001 12.2 13.0001C12 13.0001 11.8 12.9001 11.7 12.8001L9.99998 11.1001L8.29998 12.8001C8.19998 12.9001 7.99998 13.0001 7.79998 13.0001C7.59998 13.0001 7.39998 12.9001 7.19998 12.8001C6.89998 12.5001 6.89998 12.0001 7.19998 11.7001L8.89997 10.0001L7.19998 8.3001C6.89998 8.0001 6.89998 7.5001 7.19998 7.2001C7.49998 6.9001 7.99998 6.9001 8.29998 7.2001L9.99998 8.9001L11.7 7.2001Z"
        fill={color}
      />
    </svg>
  )
}

export default IconRoundedClose
