import colors from '@/styled/colors'

interface IconProps {
  width?: number
  height?: number
  color?: string
}

const IconVisibilityOff = ({ width, height, color }: IconProps) => (
  <svg
    width={width || 20}
    height={height || 20}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M1.51442 16.0709C1.1239 16.4614 1.1239 17.0946 1.51442 17.4851C1.90495 17.8757 
      2.53811 17.8757 2.92864 17.4851L6.09827 14.3155C9.75685 15.9094 14.1743 15.2101 17.167 
      12.2175L18.7779 10.6065C19.5589 9.8255 19.5589 8.55917 18.7779 7.77812L17.167 6.16721C16.6664 
      5.66663 16.1259 5.23021 15.5558 4.85795L18.485 1.92879C18.8755 1.53826 18.8755 0.905097 18.485 
      0.514573C18.0945 0.124049 17.4613 0.124049 17.0708 0.514573L13.6839 3.90146C10.1096 2.5302 5.90658 
      3.28545 3.02482 6.16721L1.41391 7.77812C0.632863 8.55917 0.632863 9.8255 1.41391 10.6065L3.02482 
      12.2175C3.42392 12.6166 3.84835 12.9749 4.29295 13.2924L1.51442 16.0709ZM7.649 12.7648C10.4117 13.6496 
      13.5602 12.9958 15.7527 10.8032L17.3637 9.19233L15.7527 7.58143C15.2478 7.07651 14.6922 6.6532 14.1023 
      6.31149L11.9319 8.48184C11.9761 8.64708 11.9997 8.82077 11.9997 8.99995C11.9997 10.1045 11.1043 11 
      9.9997 11C9.82051 11 9.64683 10.9764 9.48158 10.9322L7.649 12.7648ZM8.06742 9.51792L5.73203 
      11.8533C5.27445 11.5549 4.8407 11.2049 4.43903 10.8032L2.82812 9.19234L4.43903 7.58143C6.51383 
      5.50663 9.44473 4.80972 12.0947 5.49069L10.5177 7.06768C10.3525 7.0235 10.1788 6.99995 
      9.9997 6.99995C8.89513 6.99995 7.9997 7.89538 7.9997 8.99995C7.9997 9.17908 8.02325
       9.35272 8.06742 9.51792Z`}
      fill={color || colors.smokeText}
    />
  </svg>
)

export default IconVisibilityOff
