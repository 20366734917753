import { IconClose } from '@/assets/icons'
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import Button from '../Button'

import {
  ModalBody,
  ModalCloseButtonWithTitle,
  ModalCloseButtonWithoutTitle,
  ModalFooter,
  ModalRoot,
  ModalTitleWrapper,
  ModalWrapper,
} from './styles'
import { TypeTitleAlign } from './types'

interface Props {
  id: string
  children: ReactNode
  defaultOpened?: boolean
  title?: string
  visible: boolean
  onClose?: () => void
  titleAlign?: TypeTitleAlign
  hideBorderTitle?: boolean
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
  bodyPadding?: string
  disabledOkButton?: boolean
  loadingOkButton?: boolean
  disabledCancelButton?: boolean
  enableOverflow?: boolean
}

const Modal = (
  {
    id,
    children,
    defaultOpened = false,
    visible,
    title,
    onClose,
    titleAlign = 'center',
    hideBorderTitle = false,
    onOk,
    onCancel,
    okText,
    cancelText,
    bodyPadding,
    disabledOkButton,
    disabledCancelButton,
    loadingOkButton,
    enableOverflow,
  }: Props,
  ref: any
) => {
  const [isOpen, setIsOpen] = useState(defaultOpened)
  const [container, setContainer] = useState<HTMLDivElement>()
  const defaultClose = useCallback(() => setIsOpen(false), [])

  const isFooterExist = Boolean(onOk) || Boolean(onCancel)

  const close = () => {
    if (onClose) {
      onClose()
    } else defaultClose()
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const rootContainer = document.createElement('div')
      const parentElem = document.querySelector('#root')

      rootContainer.setAttribute('id', 'modal-root')
      parentElem?.appendChild(rootContainer)
      setContainer(rootContainer)
    }
  }, [window])

  useEffect(() => {
    setIsOpen(visible)
  }, [visible])

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close]
  )

  const handleEscape = useCallback(
    (event: any) => {
      if (event.keyCode === 27) close()
    },
    [close]
  )

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false)

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, isOpen])

  return container
    ? createPortal(
        isOpen ? (
          <ModalRoot enableOverflow={enableOverflow}>
            <ModalWrapper id={id}>
              {title && (
                <ModalTitleWrapper
                  hideBorderTitle={hideBorderTitle}
                  titleAlign={titleAlign}
                >
                  <ModalCloseButtonWithTitle onClick={close}>
                    <IconClose size={20} color="#707070" />
                  </ModalCloseButtonWithTitle>
                  {title}
                  <div style={{ width: 20 }} />
                </ModalTitleWrapper>
              )}
              {!title && onClose && (
                <ModalTitleWrapper hideBorderTitle titleAlign={titleAlign}>
                  <ModalCloseButtonWithoutTitle onClick={close}>
                    <IconClose size={20} color="#707070" />
                  </ModalCloseButtonWithoutTitle>
                </ModalTitleWrapper>
              )}
              <ModalBody
                className="modal-body"
                style={{ padding: bodyPadding }}
              >
                {children}
              </ModalBody>
              {isFooterExist && (
                <ModalFooter>
                  {Boolean(onCancel) && (
                    <Button
                      id={`${id}-cancel-button`}
                      variant="tertiary"
                      size="medium"
                      onClick={onCancel}
                      disabled={Boolean(disabledCancelButton)}
                      label={cancelText ?? 'Kembali'}
                    />
                  )}
                  {Boolean(onOk) && (
                    <Button
                      id={`${id}-ok-button`}
                      variant="primary"
                      size="medium"
                      onClick={onOk}
                      disabled={Boolean(disabledOkButton)}
                      loading={loadingOkButton}
                      label={okText ?? 'Lanjutkan'}
                    />
                  )}
                </ModalFooter>
              )}
            </ModalWrapper>
          </ModalRoot>
        ) : null,
        container
      )
    : null
}

export default forwardRef(Modal)
