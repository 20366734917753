import {
  IconBag,
  IconBagActive,
  IconHome,
  IconHomeActive,
  IconProfile,
  IconProfileActive,
} from '@/assets/svg'
import paths from './paths'

interface NavigationItem {
  label: string
  path: string
  icon: string
  iconActive: string
}

const navigations: NavigationItem[] = [
  {
    label: 'Beranda',
    icon: IconHome,
    iconActive: IconHomeActive,
    path: paths.home,
  },
  {
    label: 'Pesanan',
    icon: IconBag,
    iconActive: IconBagActive,
    path: paths.order,
  },
  {
    label: 'Profil',
    icon: IconProfile,
    iconActive: IconProfileActive,
    path: paths.profile,
  },
]

export default navigations
