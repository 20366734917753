import Text from '@/components/Text'
import navigations from '@/utils/constants/navigations'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationItem, NavigationWrapper } from './styles'

const Navigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <NavigationWrapper>
      {navigations.map((navigation, index) => {
        const currentPathName = location.pathname.split('/')
        const isActive = `/${currentPathName[1]}` === navigation.path

        return (
          <NavigationItem
            key={`navigation-${index + 1}`}
            onClick={() => navigate(navigation.path)}
          >
            <img
              src={isActive ? navigation.iconActive : navigation.icon}
              alt={navigation.label}
            />
            <Text variant="body3" color={isActive ? 'primary' : 'smokeText'}>
              {navigation.label}
            </Text>
          </NavigationItem>
        )
      })}
    </NavigationWrapper>
  )
}

export default Navigation
