import { FC } from 'react'

interface IconProps {
  size?: number
  color?: string
}

const IconCheck: FC<IconProps> = ({ size = 24, color = '#202020' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2937 1.13981C23.6839 1.53004 23.6842 2.16261 23.2944 2.55326L9.3412 16.5367C8.95653 16.9222 8.33409 16.929 7.94108 16.552L0.752304 9.65613C0.341976 9.26252 0.341975 8.60643 0.752304 8.21282L1.30359 7.68399C1.69048 7.31287 2.30121 7.31287 2.6881 7.68399L8.64865 13.4017L21.4468 0.701678C21.8377 0.313716 22.4688 0.31493 22.8582 0.704392L23.2937 1.13981Z"
        fill={color}
      />
    </svg>
  )
}

export default IconCheck
