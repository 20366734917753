import { styled } from '@/styled'

export const MainWrapper = styled('div', {
  backgroundColor: '$white',
  position: 'relative',
  width: '100vw',
  height: '100vh',
  maxWidth: '768px',
  overflow: 'hidden',
  margin: '0px auto',
  display: 'flex',
  flexDirection: 'column',
})

export const MainContent = styled('div', {
  height: '100vh',
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    useNavigation: {
      true: {
        // marginBottom: '68px',
      },
      false: {},
    },
    useHeader: {
      true: {
        // marginTop: '60px',
      },
      false: {},
    },
  },
})
