interface IconProps {
  size?: number
}

const ArrowLeft = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21967 11.2197C2.92678 11.5126 2.92678 11.9874 3.21967 12.2803L11.2197 20.2803C11.5126 20.5732 11.9874 20.5732 12.2803 20.2803C12.5732 19.9874 12.5732 19.5126 12.2803 19.2197L5.56066 12.5H20.25C20.6642 12.5 21 12.1642 21 11.75C21 11.3358 20.6642 11 20.25 11L5.56066 11L12.2803 4.28033C12.5732 3.98744 12.5732 3.51256 12.2803 3.21967C11.9874 2.92678 11.5126 2.92678 11.2197 3.21967L3.21967 11.2197Z"
        fill="#3A3A3A"
      />
    </svg>
  )
}

export default ArrowLeft
