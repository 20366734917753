interface Props {
  size?: number
  color?: string
}

const IconInfo = ({ size = 20, color = '#00A2D8' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 18.3334C5.4165 18.3334 1.6665 14.5834 1.6665 10.0001C1.6665 5.41675 5.4165 1.66675 9.99984 1.66675C14.5832 1.66675 18.3332 5.41675 18.3332 10.0001C18.3332 14.5834 14.5832 18.3334 9.99984 18.3334ZM9.99984 2.91675C6.08317 2.91675 2.9165 6.08342 2.9165 10.0001C2.9165 13.9167 6.08317 17.0834 9.99984 17.0834C13.9165 17.0834 17.0832 13.9167 17.0832 10.0001C17.0832 6.08342 13.9165 2.91675 9.99984 2.91675ZM11.2498 13.8333H10.2498C9.7498 13.8333 9.33313 13.4167 9.33313 13V9.5H8.7498C8.33313 9.5 8.08313 9.25 8.08313 8.91667C8.08313 8.58333 8.33313 8.25 8.7498 8.25H9.7498C10.2498 8.25 10.6665 8.66667 10.6665 9.08333V12.5833H11.3331C11.6665 12.5833 11.9998 12.8333 11.9998 13.25C11.9998 13.6667 11.6665 13.8333 11.2498 13.8333ZM10.6665 6.66671C10.6665 7.0349 10.368 7.33337 9.9998 7.33337C9.63161 7.33337 9.33313 7.0349 9.33313 6.66671C9.33313 6.29852 9.63161 6.00004 9.9998 6.00004C10.368 6.00004 10.6665 6.29852 10.6665 6.66671Z"
        fill={color}
      />
    </svg>
  )
}

export default IconInfo
