import Loading from '@/components/Loading'
import { Fragment, Suspense, useEffect } from 'react'
import { MainContent, MainWrapper } from './styles'
import { LayoutProps } from './types'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import paths from '@/utils/constants/paths'
import Cookies from 'js-cookie'
import { COOKIE_AUTHORIZATION } from '@/utils/constants/cookie'
import Header from './components/Header'
import Navigation from './components/Navigation'
import { ModalProvider } from '@/components/Modal/modalContext'

const Layout = ({
  Container,
  title,
  useHeader,
  useAuth,
  useNavigation,
  showTitle,
  useRefresh,
}: LayoutProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    const getCookieAuth = Cookies.get(COOKIE_AUTHORIZATION)
    if (params.get('token')) {
      Cookies.set(COOKIE_AUTHORIZATION, String(params.get('token')))
      window.location.href = location.pathname
    }
    if (useAuth && !getCookieAuth) {
      navigate(paths.login)
    }
    if (!useAuth && getCookieAuth) {
      navigate(paths.home)
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>{`Mitra PooK PoW - ${title}`}</title>
      </Helmet>
      <Suspense fallback={<Loading />}>
        <ModalProvider>
          <MainWrapper>
            {useHeader && (
              <Header title={showTitle ? title : ''} useRefresh={useRefresh} />
            )}
            <MainContent useNavigation={useNavigation} useHeader={useHeader}>
              <Container />
            </MainContent>
            {useNavigation && <Navigation />}
          </MainWrapper>
        </ModalProvider>
      </Suspense>
    </Fragment>
  )
}

export default Layout
