import { IconArrowLeft } from '@/assets/icons'
import Button from '@/components/Button'
import Text from '@/components/Text'
import { useNavigate } from 'react-router-dom'
import { HeaderWrapper } from './styles'
import { HeaderProps } from './types'
import { FiRefreshCw } from 'react-icons/fi'

const Header = ({ title, useRefresh }: HeaderProps) => {
  const navigate = useNavigate()

  return (
    <HeaderWrapper>
      <Button variant="icon" onClick={() => navigate(-1)}>
        <IconArrowLeft />
      </Button>
      {title && (
        <Text variant="body1" weight="semibold">
          {title}
        </Text>
      )}

      {useRefresh ? (
        <Button variant="icon" onClick={() => window.location.reload()}>
          <FiRefreshCw size={20} />
        </Button>
      ) : (
        <div style={{ width: 26 }} />
      )}
    </HeaderWrapper>
  )
}

export default Header
