import { Fragment, lazy, Suspense } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routes from '@/routes'
import Layout from '@/views/layouts'
import globalStyles from './styled/global'
import { QueryClientProvider } from 'react-query'

import Loading from './components/Loading'
const Page404 = lazy(() => import('@/views/errors/Page404'))

import { AxiosError } from 'axios'
import { MutationCache, QueryCache, QueryClient } from 'react-query'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const App = () => {
  globalStyles()

  const handleError = (error: AxiosError) => {
    const errorData = (error as any).response?.data

    if (errorData.status !== 404) {
      toast.error(errorData?.message)
    }
  }

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
    queryCache: new QueryCache({
      onError: (error) => handleError(error as AxiosError),
    }),
    mutationCache: new MutationCache({
      onError: (error) => handleError(error as AxiosError),
    }),
  })

  return (
    <Fragment>
      <ToastContainer />
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <Routes>
            {routes.map(
              (
                {
                  path,
                  container,
                  title,
                  useHeader,
                  useAuth,
                  showTitle,
                  useNavigation,
                  useRefresh,
                },
                i
              ) => (
                <Route
                  key={i}
                  path={path}
                  element={
                    <Layout
                      Container={container}
                      title={title}
                      useHeader={useHeader}
                      useAuth={useAuth}
                      useNavigation={useNavigation}
                      showTitle={showTitle}
                      useRefresh={useRefresh}
                    />
                  }
                />
              )
            )}
            <Route
              path="*"
              element={
                <Suspense fallback={<Loading />}>
                  <Page404 />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  )
}
export default App
