import { forwardRef, useMemo } from 'react'
import Text from '../Text'

import { StyledButton } from './styles'
import type { ButtonProps } from './types'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      label,
      loading,
      prefix,
      variant,
      onKeyDown,
      weight,
      id,
      ...otherProps
    },
    ref
  ) => {
    const fontWeight = useMemo(() => {
      if (weight) return weight
      else {
        return 'regular'
      }
    }, [weight])

    return (
      <StyledButton
        ref={ref}
        className={`${loading ? 'loading' : ''}`}
        {...otherProps}
        variant={variant}
        onKeyDown={onKeyDown}
        id={id}
      >
        {prefix && children}
        {label && (
          <Text variant="body2" paragraph weight={fontWeight}>
            {label}
          </Text>
        )}
        {!prefix && children}
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  loading: false,
}

export default Button
