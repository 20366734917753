const paths = {
  login: '/login',
  loginForm: '/login/form',
  home: '/',
  order: '/orders',
  profile: '/profile',
  profilePrivacyPolicy: '/profile/privacy-policy',
  profileOrderNeed: '/profile/order-need',
  profileShippingAddress: '/profile/shipping-address',
  profileTotalProductSold: '/profile/product-sold',
  profileStore: '/profile/store',
  profileWalletHistory: '/profile/wallet-history',
  products: '/products',
  productsForm: '/products/form',
}

export default paths
