import { FC } from 'react'

interface IconProps {
  width?: number
  height?: number
  color?: string
}

const IconSuccess: FC<IconProps> = ({ height = 120, color = '#00A050' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      fill="none"
      viewBox={`0 0 ${height} ${height}`}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M0 60C0 26.863 26.863 0 60 0s60 26.863 60 60-26.863 60-60 60S0 93.137 0 60z"
        clipRule="evenodd"
        opacity="0.3"
      />
      <g filter="url(#filter0_d_12793:91560)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20 60c0-22.091 17.909-40 40-40s40 17.909 40 40-17.909 40-40 40-40-17.909-40-40z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="4"
        d="M46.828 58.898L55.314 67.384"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="4"
        d="M55.313 67.385L73.698 49"
      />
      <defs>
        <filter
          id="filter0_d_12793:91560"
          width={height}
          height={height}
          x="0"
          y="10"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.627451 0 0 0 0 0.313726 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12793:91560"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_12793:91560"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default IconSuccess
