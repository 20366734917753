import { FC } from 'react'

interface IconProps {
  size?: number
  color?: string
}

const IconSearch: FC<IconProps> = ({ size = 24, color = '#9E9E9E' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.7669"
        cy="11.7669"
        r="8.98856"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0186 18.4854L21.5426 22.0002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconSearch
