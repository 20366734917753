import { lazy } from 'react'
import paths from './utils/constants/paths'
const Homepage = lazy(() => import('@/views/pages/Homepage'))
const Login = lazy(() => import('@/views/pages/Login'))
const LoginForm = lazy(() => import('@/views/pages/LoginForm'))
const Profile = lazy(() => import('@/views/pages/Profile'))
const PrivacyPolicy = lazy(() => import('@/views/pages/Profile/PrivacyPolicy'))
const Store = lazy(() => import('@/views/pages/Profile/Store'))
const ProfileAddress = lazy(() => import('@/views/pages/Profile/Address'))
const ProfileOrderNeed = lazy(() => import('@/views/pages/Profile/OrderNeed'))
const ProfileProductSold = lazy(
  () => import('@/views/pages/Profile/ProductSold')
)
const ProfileWalletHistory = lazy(
  () => import('@/views/pages/Profile/WalletHistory')
)
const Products = lazy(() => import('@/views/pages/Products/List'))
const ProductsForm = lazy(() => import('@/views/pages/Products/Form'))
const Orders = lazy(() => import('@/views/pages/Order/List'))

const routes: {
  title: string
  container: React.LazyExoticComponent<() => JSX.Element>
  path: string
  useHeader: boolean
  useAuth?: boolean
  showTitle?: boolean
  useNavigation?: boolean
  useRefresh?: boolean
}[] = [
  {
    title: 'Homepage',
    container: Homepage,
    path: paths.home,
    useHeader: false,
    useAuth: true,
    useNavigation: true,
  },
  {
    title: 'Login',
    container: Login,
    path: paths.login,
    useHeader: false,
  },
  {
    title: 'Login Form',
    container: LoginForm,
    path: paths.loginForm,
    useHeader: true,
  },
  {
    title: 'Profil',
    container: Profile,
    path: paths.profile,
    useHeader: true,
    useNavigation: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Kebijakan Privasi',
    container: PrivacyPolicy,
    path: paths.profilePrivacyPolicy,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Buat Profil Toko',
    container: Store,
    path: paths.profileStore,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Kelola Alamat Toko',
    container: ProfileAddress,
    path: paths.profileShippingAddress,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Laporan Penjualan Produk',
    container: ProfileProductSold,
    path: paths.profileTotalProductSold,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Mutasi Saldo',
    container: ProfileWalletHistory,
    path: paths.profileWalletHistory,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Transfer',
    container: ProfileOrderNeed,
    path: paths.profileOrderNeed,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Toko Saya',
    container: Products,
    path: paths.products,
    useHeader: true,
    useAuth: true,
    showTitle: true,
    useRefresh: true,
  },
  {
    title: 'Tambah Produk',
    container: ProductsForm,
    path: paths.productsForm,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Edit Produk',
    container: ProductsForm,
    path: `${paths.productsForm}/:id`,
    useHeader: true,
    useAuth: true,
    showTitle: true,
  },
  {
    title: 'Pesanan Toko',
    container: Orders,
    path: paths.order,
    useHeader: true,
    useNavigation: true,
    useAuth: true,
    showTitle: true,
    useRefresh: true,
  },
]

export default routes
