import { styled } from '@/styled'

export const NavigationWrapper = styled('div', {
  height: '68px',
  display: 'flex',
  width: '100%',
  zIndex: 9,
  backgroundColor: '$white',
  boxShadow: '0px 0px 24px 0px rgba(10, 2, 26, 0.15)',
})

export const NavigationItem = styled('div', {
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
})
